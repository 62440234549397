<template>
  <div id="asset">
    <index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>招生</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">招生信息</a></el-breadcrumb-item>
      </el-breadcrumb>

      <v-weburl-iframe class="content" url="student/enroll" />
    </index>
  </div>
</template>

<script>
import index from '@/components/common/index.vue';
import VWeburlIframe from "@/components/weburl-iframe/index.vue";

export default {
  components: {VWeburlIframe, index}
}
</script>
